<template>
  <div
    :class="[
      $style.base,
      {
        [$style.smallScreen]: $screen === 's',
        [$style.navExpanded]: isNavExpanded
      }
    ]"
  >
    <TheGlobalModals />
    <FloatingBanner />
    <TheFlashMessage />
    <transition appear>
      <NavMain :class="$style.nav" />
    </transition>
    <ScrollContainer>
      <template #header>
        <transition appear>
          <Header v-if="!isBlocked" :class="$style.header" />
          <BlockedUserHeader v-else :class="$style.header" />
        </transition>
      </template>
      <template #page>
        <router-view :class="$style.page" />
      </template>
    </ScrollContainer>
  </div>
</template>

<script lang="ts">
import { hideTooltip, modal } from '@/helpers/ui';
import TheGlobalModals from './global-modals/index.vue';
import FloatingBanner from './floating-banner/index.vue';
import TheFlashMessage from './TheFlashMessage.vue';
import NavMain from './nav-main/index.vue';
import Header from './header/index.vue';
import BlockedUserHeader from './header/BlockedUserHeader.vue';
import ScrollContainer from './ScrollContainer.vue';
import { useLocationsStore } from '@/stores/locations';
import { mapState } from 'pinia';
import { useCompanyStore } from '@/stores/company';
import { useUserStore } from '@/stores/user';
import { usePageLayoutStore } from '@/stores/page-layout';
import { device } from '@/user-context';
import { defineComponent } from 'vue';
import { useIntercom } from './intercom';
import { useGlobalSubscription } from './global-subscription';
import { PaymentGatewayStatus } from '@/types';
import { useStorage } from '@vueuse/core';
import dayjs from '@/dayjs';
import { useTreatwellStore } from '@/stores/treatwell';

let modalObserver;

export default defineComponent({
  name: 'LoggedIn',
  components: {
    FloatingBanner,
    BlockedUserHeader,
    Header,
    TheFlashMessage,
    NavMain,
    TheGlobalModals,
    ScrollContainer
  },
  inject: ['mixpanel'],
  setup() {
    useIntercom();

    const { startSubscription } = useGlobalSubscription();
    startSubscription();
  },
  watch: {
    location: {
      handler() {
        const { isTreatwellUser } = useCompanyStore();
        document.title = `${this.multiLocation ? this.location.internalName + ' - ' : ''}${this.company.name} - ${isTreatwellUser ? 'Treatwell' : 'Salonized'}`;
      },
      immediate: true
    },
    company: 'setMixpanelSuperProps',
    user: 'setMixpanelSuperProps',
    '$route.name': {
      handler() {
        this.setMixpanelSuperProps();
      },
      immediate: true
    }
  },
  computed: {
    ...mapState(useUserStore, ['user', 'hasFeatureFlag']),
    ...mapState(useCompanyStore, [
      'company',
      'multiLocation',
      'companyNeedsType',
      'isTrial',
      'isTrialExpired',
      'isBlocked',
      'isTreatwellUser'
    ]),
    ...mapState(useLocationsStore, ['location']),
    ...mapState(usePageLayoutStore, [
      'isMobileDesktop',
      'isModalOpen',
      'isNavExpanded'
    ]),
    ...mapState(useTreatwellStore, ['treatwellStatus'])
  },
  methods: {
    onScroll() {
      hideTooltip();
    },
    setMixpanelSuperProps() {
      if (this.mixpanel) {
        const {
          owner,
          admin,
          impersonated,
          id: user_id,
          salonizedAdmin: salonized_admin
        } = this.user || {};
        const userData = {
          owner,
          admin,
          impersonated,
          user_id,
          salonized_admin
        };

        const { id: company_id, blocked } = this.company;
        const companyData = { company_id, blocked };

        const normalizedPath = `${window.location.pathname}`
          .replace(/\/(c|l)\/[0-9]+/g, '') // Strip company and location id
          .replace(/\/?\?.*?$/, '') // Strip query params
          .replace(/\d{4}-\d{2}-\d{2}/g, '{date}') // Replace dates with generic placeholder
          .replace(/\d+/g, '{id}'); // Replace id's with generic placeholder

        const browserData = {
          path: normalizedPath,
          host: window.location.hostname,
          device: device.mobile ? 'mobile' : device.touch ? 'tablet' : 'desktop'
        };

        const mixpanelData = {
          ...browserData,
          ...userData,
          ...companyData,
          ...this.company.mixpanelData,
          location: 'frontend',
          mobile_desktop_view: this.isMobileDesktop,
          treatwell_pilot: this.isTreatwellUser
        };

        this.mixpanel.register(mixpanelData);
      }
    }
  },
  created() {
    if (this.mixpanel) {
      this.mixpanel.identify(this.user.id);
      this.mixpanel.track('user_logged_in');

      if (this.treatwellStatus.kycStatus?.kycCompleted) {
        this.mixpanel.track('tw_kyc_completed');
      }
    }

    document.body.addEventListener('scroll', this.onScroll);

    const modalContainer = document.getElementById('modals');
    if (modalContainer) {
      const pageLayoutStore = usePageLayoutStore();
      modalObserver = new MutationObserver(() => {
        pageLayoutStore.isModalOpen = modalContainer.hasChildNodes();
      });

      modalObserver.observe(modalContainer, { childList: true });
    }
  },
  mounted() {
    const mollieErrorRemindMeLaterAt = useStorage(
      'mollie_error_remind_me_later_at',
      0
    );
    if (
      this.company.paymentGatewayStatus === PaymentGatewayStatus.MollieError &&
      dayjs().diff(mollieErrorRemindMeLaterAt.value, 'day', true) > 3
    ) {
      modal('mollie-error');
    }
  },
  beforeUnmount() {
    document.body.removeEventListener('scroll', this.onScroll);
    modalObserver.disconnect();
  }
});
</script>

<style lang="scss" module>
.base {
  height: 100%;
  transition: padding 0.3s $easeOutExpo;

  &:not(.smallScreen) {
    padding-left: $nav-width;

    &.navExpanded {
      padding-left: $nav-width-expanded;
    }
  }
}

.page {
  .base.smallScreen & {
    padding-top: 50px;
    height: 100%;
  }

  .base.modalOpen & {
    z-index: 999;
  }
}

.nav,
.header {
  &:global(.v-enter-active) {
    transition: transform 0.2s $easeOutExpo;
  }
}

.nav {
  &:global(.v-enter-from),
  &:global(.v-leave-to) {
    transform: translateX(-100%);
  }
}

.header {
  &:global(.v-enter-from),
  &:global(.v-leave-to) {
    transform: translateY(-100%);
  }
}

@media print {
  .base {
    &:not(.smallScreen) {
      padding-left: 0;
    }
  }
}
</style>
