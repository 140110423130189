<template>
  <RouterLink
    :to="item.routeName ? { name: item.routeName } : null"
    :active-class="$style.isActive"
    :class="[
      { [$style.mainItem]: mainItem, [$style.secondaryItem]: !mainItem }
    ]"
    @click="close"
    v-test="`nav-main-${item.routeName || item.name}`"
  >
    <BaseIcon
      :name="item.icon"
      color="inverted"
      :size="!mainItem ? 's' : 'm'"
    />
    <BaseText color="inverted" :ml="mainItem ? undefined : 1">{{
      item.name
    }}</BaseText>
    <BaseNotification
      v-if="item.notification && item.notification.show"
      inline
      :ml="0.5"
      v-test="`nav-main-notification-${item.routeName}`"
    >
      {{ item.notification.value }}
    </BaseNotification>
  </RouterLink>
</template>

<script setup lang="ts">
import type { NavItem } from './MobileOverlay.vue';
import { usePageLayoutStore } from '@/stores/page-layout';
import { storeToRefs } from 'pinia';
const { showMobileMenu } = storeToRefs(usePageLayoutStore());

const close = () => (showMobileMenu.value = false);

defineProps<{
  item: NavItem;
  mainItem?: boolean;
}>();
</script>

<style lang="scss" module>
.secondaryItem {
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  padding: 1.7vh $spacing;
  &:not(:last-child) {
    border-bottom: 1px solid rgba(191, 191, 191, 0.4);
  }

  @media (max-height: 710px) {
    padding: 1.3vh $spacing;
  }
  @media (max-height: 620px) {
    padding: 1vh $spacing;
  }

  &:focus {
    background: $color-primary-dark;
  }

  @include hover {
    background: $color-primary;
  }
}

.mainItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: $spacing;
  gap: 0.25 * $spacing;
  flex-basis: 33%;
  background: $color-primary;
  border: 1px solid $color-primary-dark;
  border-radius: $radius;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.15);
}
</style>
