<template>
  <div
    v-if="hasFeatureFlag('franchise-scope') || multiLocation"
    :class="$style.base"
  >
    <div
      :class="$style.button"
      @click="showModal = true"
      v-test="'mobile-context'"
    >
      <BaseIcon name="globe" :color="showMobileMenu ? 'inverted' : undefined" />
    </div>
    <BaseModalSmall v-if="showModal" @close="showModal = false">
      <template #center>
        <div :class="$style.content">
          <FranchiseSelect v-if="hasFeatureFlag('franchise-scope')" />
          <LocationSelect v-if="multiLocation" @select="showModal = false" />
        </div>
      </template>
    </BaseModalSmall>
  </div>
</template>

<script setup lang="ts">
import { usePageLayoutStore } from '@/stores/page-layout';
import { storeToRefs } from 'pinia';
import LocationSelect from './LocationSelect.vue';
import FranchiseSelect from './FranchiseSelect.vue';
import { useCompanyStore } from '@/stores/company';
import { useUserStore } from '@/stores/user';

const { showMobileMenu } = storeToRefs(usePageLayoutStore());
const { multiLocation } = useCompanyStore();
const { hasFeatureFlag } = useUserStore();

const showModal = ref(false);
</script>

<style lang="scss" module>
.base {
  width: 100%;
  height: 100%;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.content {
  display: flex;
  flex-direction: column;
  gap: $spacing;
  margin-top: $spacing * 3;
}
</style>
