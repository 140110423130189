<template>
  <div :class="$style.base">
    <BaseIcon
      name="search"
      :tooltip="
        device.touch
          ? undefined
          : {
              position: 'bottom',
              text: $t('search.tooltip')
            }
      "
      :color="showMobileMenu ? 'inverted' : undefined"
    />
  </div>
</template>

<script setup lang="ts">
import { usePageLayoutStore } from '@/stores/page-layout';
import { device } from '@/user-context';
import { storeToRefs } from 'pinia';

const { showMobileMenu } = storeToRefs(usePageLayoutStore());
</script>

<style lang="scss" module>
.base {
  @include header-item;
  margin-left: 0;
}
</style>
