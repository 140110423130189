<template>
  <div :class="$style.base">
    <MainItems :items="filterLinks(mainItems)" @navItemClick="close" />
    <div :class="$style.scroll">
      <SecondaryItems mt :items="filterLinks(navItems)" @navItemClick="close" />
      <SecondaryItems
        mt
        :items="filterLinks(secondaryNavItems)"
        @navItemClick="close"
      />
    </div>
    <Footer mt :mb="1.5" />
  </div>
</template>

<script setup lang="ts">
import MainItems from './MainItems.vue';
import SecondaryItems from './SecondaryItems.vue';
import Footer from './Footer.vue';

import type { IconName } from '@/components/base-icon/types';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { isRouteAllowed } from '@/router/helpers';
const router = useRouter();
const { t } = useI18n();

import { useUserStore } from '@/stores/user';
import { useTreatwellStore } from '@/stores/treatwell';
const { user } = useUserStore();
const { showTWOnboardingNotification, showTreatwell } = useTreatwellStore();

const emit = defineEmits(['navItemClick']);

export interface NavItem {
  icon: IconName;
  routeName: string;
  name: string;
  notification?: {
    show?: boolean;
    value?: number | string;
  };
  hide?: boolean;
}

const filterLinks = (links: NavItem[]) =>
  links.filter((link) => {
    const route = router.resolve({ name: link.routeName });
    return !!route && !link.hide && isRouteAllowed(route);
  });

const mainItems: NavItem[] = [
  {
    icon: 'home',
    routeName: 'dashboard',
    name: t('nav.dashboard')
  },

  {
    icon: 'calendar',
    routeName: 'calendar',
    name: t('nav.calendar')
  },
  {
    icon: 'shopping-cart',
    routeName: 'register',
    name: t('nav.register')
  }
];

const navItems: NavItem[] = [
  {
    icon: 'people',
    routeName: 'customers',
    name: t('nav.customers')
  },
  {
    icon: 'pricetag',
    routeName: 'products',
    name: t('nav.products')
  },
  {
    icon: 'email',
    routeName: 'messages',
    name: t('nav.messages')
  },
  {
    icon: 'message',
    routeName: 'feedback',
    name: t('nav.feedback'),
    notification: {
      show: (user && user.feedbacks > 0) || undefined,
      value: user?.feedbacks
    }
  },
  {
    icon: 'activity',
    routeName: 'reports',
    name: t('nav.reports')
  },
  {
    icon: 'marketing',
    routeName: 'marketing',
    name: t('nav.marketing')
  },
  {
    icon: 'treatwell',
    routeName: 'treatwell-admin',
    name: t('nav.treatwell'),
    hide: !showTreatwell,
    notification: {
      show: showTWOnboardingNotification.value,
      value: '!'
    }
  }
];
const secondaryNavItems: NavItem[] = [
  {
    icon: 'person',
    routeName: 'account',
    name: t('nav.account')
  },
  {
    icon: 'settings',
    routeName: 'admin',
    name: t('nav.settings')
  },
  {
    icon: 'info',
    routeName: 'new-features',
    name: t('nav.support_hub')
  }
];

const close = () => {
  emit('navItemClick');
};
</script>

<style lang="scss" module>
.base {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: linear-gradient(#4c49c5 3.29%, #262563 47.22%);
  padding: $spacing * 0.75;

  @media (max-height: 710px) {
    padding: $spacing * 0.5 $spacing * 0.75;
  }
}

.scroll {
  overflow-y: auto;
  flex-grow: 1;
}
</style>
